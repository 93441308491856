import React, { useEffect, useRef } from 'react';
import './Projects.css'; 

const Projects = () => {

  const elementRef = useRef(null);

  useEffect(() => {

    let currentElement = elementRef.current; // Copy to a variable

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Add your animation logic here
          elementRef.current.classList.add('animate');
          observer.unobserve(entry.target); // Stop observing once animated
        }
      });
    });

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  return (
    <section id="projects">
      <div className='container' ref={elementRef}>
        <div>
          <h1>Some Things I’ve Built</h1>
        </div>

        <div className="grid-container-1">
          <a href='https://store.steampowered.com/app/1056600/Home_Sweet_Home__Survive/' target="_blank" rel="noreferrer" className='image1'>
            <img src="./assets/img/project_hsh.jpg" alt="" />
          </a>
          <a href='https://store.steampowered.com/app/539300/Solitaire/' target="_blank" rel="noreferrer" className='image2'>
            <img src="./assets/img/project_solitaire.jpg" alt="" />
          </a>
        </div>

        <div className="grid-container-2">
          <a href='https://store.playstation.com/en-us/product/UP0036-CUSA14530_00-WESUSGAME0000000' target="_blank" rel="noreferrer" className='image3'>
            <img src="./assets/img/project_ws.jpg" alt="" />
          </a>
          <a href='https://store.steampowered.com/app/839450/7scarlet/' target="_blank" rel="noreferrer" className='image4'>
            <img src="./assets/img/project_7s.png" alt="" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Projects;