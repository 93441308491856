import React, { useEffect, useState, useRef } from 'react';
import './About.css'; 

const About = () => {

  const elementRef = useRef(null);
  const timeLineRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Add your animation logic here
          elementRef.current.classList.add('fadeInDown');
          timeLineRef.current.classList.add('fadeInUp');
          observer.unobserve(entry.target); // Stop observing once animated
        }
      });
    });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <section id="about">
      <div className="grid-container" ref={elementRef}>
        <div className="profile-img">
            <img src="./assets/img/profile_img.png" />
        </div>
        <div className="about-me">
          <h2>A dedicated game developer and a versatile full-stack developer. Welcome to my portfolio!</h2>
          <p>Over the past 6+ years, I've worked in various areas of game development, including a full-stack development, I've contributed to various titles, spanning mobile to console</p>
          <p>I'm a versatile developer, problem solver, ready and happy to transform your vision into reality.</p>
        </div>
      </div>
      <div className="timeline" ref={timeLineRef}>
          <div className="timeline-item item-0">
            <div className="timeline-name">
              <p className="bold">Thai Multiverse Expert</p>
              <p>Full-Stack developer</p>
            </div>
            <div className="timeline-duration">
              <p>22-present</p>
            </div>
          </div>
          <div className="timeline-item item-1">
            <div className="timeline-name">
              <p className="bold">Yggdrazil</p>
              <p>Sr Game developer</p>
            </div>
            <div className="timeline-duration">
              <p>2019-2021</p>
            </div>
          </div>
          <div className="timeline-item item-2">
            <div className="timeline-name">
              <p className="bold">Global Wireless</p>
              <p>Freelance</p>
            </div>
            <div className="timeline-duration">
              <p>2020-2020</p>
            </div>
          </div>
          <div className="timeline-item item-3">
            <div className="timeline-name">
              <p className="bold">Sanuk software</p>
              <p>Game developer</p>
            </div>
            <div className="timeline-duration">
              <p>2015-2019</p>
            </div>
          </div>
        </div>
    </section>
  );
};

export default About;
