import React, { useEffect, useRef } from 'react';
import './Intro.css'; 
import Adjuster from './Adjuster';

const Intro = () => {

  const elementRef = useRef(null);

  useEffect(() => {

    let currentElement = elementRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Add your animation logic here
          elementRef.current.classList.add('animate');
          observer.unobserve(entry.target); // Stop observing once animated
        }
      });
    });

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  return (
    <section id="intro" >
      <div ref={elementRef}>
        <Adjuster />
        <h1>Hi, I'm Pratchayawat</h1>
        <h2>A Developer.</h2>
        <p>
        Highly skilled and versatile developer 
        specializing in game development, 
        with a strong proficiency in full-stack development
        </p>
      </div>
    </section>
  );
};

export default Intro;
