import React, { useState, useEffect } from 'react';
import Loading from './components/Loading';
import Navigation from './components/Navigation';
import Header from './components/Header';
import Intro from './components/Intro';
import About from './components/About';
import Projects from './components/Projects';
import Noteworthy from './components/Noteworthy';
import Contact from './components/Contact';

import './App.css';

function App() {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      // Simulate loading for x seconds
      await new Promise(resolve => setTimeout(resolve, 500));
      setIsLoading(false);
    };

    loadData();
  }, []);

  if (isLoading) return <Loading />

  return (
    <div className="App">
        <Navigation />
        {/* <Header /> */}
        <Intro />
        <About />
        <Projects />
        <Noteworthy />
        <Contact />
    </div>
  );
}

export default App;
