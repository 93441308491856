import React, { useEffect, useRef } from 'react';
import './Noteworthy.css'; 

const Noteworthy = () => {

  const elementRef = useRef(null);

  useEffect(() => {

    let currentElement = elementRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Add your animation logic here
          elementRef.current.classList.add('animate');
          observer.unobserve(entry.target); // Stop observing once animated
        }
      });
    });

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  return (
    <section id="noteworthy">
      <div className='container' ref={elementRef}>
        <div>
          <h1>Noteworthy Projects</h1>
        </div>

        <div className='content'>
            <a href='https://store.steampowered.com/app/539290/Brick_Breaker/' target="_blank" rel="noreferrer">
                <img src="./assets/img/project_bb.jpg" alt="" />
            </a>

            <a href='https://play.google.com/store/apps/details?id=com.ygg.herofunfun.archery&pcampaignid=web_share' target="_blank" rel="noreferrer">
                <img src="./assets/img/project_hffarchery.png" alt="" />
            </a>

            <a href='https://play.google.com/store/apps/details?id=com.ygg.herofunfun.thedragonknight&pcampaignid=web_share' target="_blank" rel="noreferrer">
                <img src="./assets/img/project_hffknight.jpg" alt="" />
            </a>

            <button href='/' target="_blank">
                <img src="./assets/img/project_personal.png" alt="" />
            </button>
        </div>
      </div>
    </section>
  );
  };
  
  export default Noteworthy;