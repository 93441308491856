import React, { useEffect, useRef } from 'react';
import './Contact.css'; 

const Contact = () => {

  const elementRef = useRef(null);

  useEffect(() => {

    let currentElement = elementRef.current; // Copy to a variable

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Add your animation logic here
          elementRef.current.classList.add('animate');
          observer.unobserve(entry.target); // Stop observing once animated
        }
      });
    });

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  return (
    <section id="contact">
      <div className='container' ref={elementRef}>
        <div>
          <h1>Get In Touch</h1>
        </div>
        <div id='content-container'>
          <div className='social'>
            <ul>
              <li>
                <a href="https://www.facebook.com/pratchayawat" target="_blank" rel="noreferrer">
                  <img src='./assets/img/icon_fb.png' alt="" />
                </a>
                <a href="https://www.linkedin.com/in/pratchayawat-towseng-324689196" target="_blank" rel="noreferrer">
                  <img src='./assets/img/icon_ln.png' alt="" />
                </a>
                <a href="mailto:pokciiz@hotmail.co.th">
                  <img src='./assets/img/icon_mail.png' alt="" />
                </a>
              </li>
            </ul>
            <p>© 2023 Pratchayawat  </p>
          </div>
        
          <div className="elsewhere">
            <p>Elsewhere</p>
            <ul>
              <li>
                <a href="https://drive.google.com/file/d/1insu5kQVNUlVLp5beuTlkcy24bpJwYaD/view?usp=sharing" target="_blank" rel="noreferrer">Resume</a>
              </li>
              <li>
                <a href="https://github.com/Pratchayawat" target="_blank" rel="noreferrer">Github</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
