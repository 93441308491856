import './Loading.css'; 

const Loading = () => {

  return (
    <div className='loading'>
        <div class="lds-ripple">
            <div>
            </div>
            <div>
            </div>
        </div>
    </div>
  );
};

export default Loading;
