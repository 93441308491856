import React, { useEffect, useRef, useState } from 'react';
import './Adjuster.css'; 

const Adjuster = () => {

    const [pageWidth, setPageWidth] = useState(0);
    const [pageHeight, setPageHeight] = useState(0);

    useEffect(() => {
        // Update the pageHeight state with the current viewport height
        const updatePageHeight = () => {
            setPageWidth(document.body.clientWidth);
            setPageHeight(document.body.clientHeight * 1.93);
        };
    
        // Listen for window resize events to keep the height up-to-date
        window.addEventListener('resize', updatePageHeight);
    
        // Initial page height calculation
        updatePageHeight();
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', updatePageHeight);
        };
    }, []);

    return (
        <section id='adjuster'>
            <label>
                <input type='checkbox' />
                <ion-icon name="sunny" class="sun"></ion-icon>
                <ion-icon name="moon" class="moon"></ion-icon>
                <span className="toggle" />
                <span className="animateBg" style={{ width: `${pageWidth}px`, height: `${pageHeight}px` }} />
            </label>
        </section>
    );
};

export default Adjuster;