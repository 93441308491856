import React, { useState, useRef, useEffect } from 'react';
import './Navigation.css'; 

function Navigation() {
  const [highlight, setHighlight] = useState(null);
  const [highlightStyle, setHighlightStyle] = useState({});
  const [prevHighlightStyle, setPrevHighlightStyle] = useState({});
  const highlightRef = useRef(null);

  const handleItemClick = (item, index) => {
    setHighlight(item);

    const target = document.getElementById(`item-${index}`);
    if (target) {
      setHighlightStyle({
        width: `${target.offsetWidth}px`,
        left: `${target.offsetLeft}px`,
        top: `${target.offsetTop}px`
      });
    }

    const element = document.getElementById(item.toLowerCase());
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const DisplayHighlight = (b) => {
    const activeIndex = highlight ? items.indexOf(highlight) : -1;
    if (activeIndex !== -1) {
      const target = document.getElementById(`item-${activeIndex}`);
      if (target) {
        const left = target.offsetLeft;
        const width = target.offsetWidth;
        const top = target.offsetTop;
        setHighlightStyle({ left: `${left}px`, width: `${width}px`, top: `${top}px`, visibility: `${b == true ? "visible" : "hidden"}`, opacity: `${b == true ? 1 : 0}` });
      }
    }
  };

  useEffect(() => {
    let resizeTimer;
    const handleResize = () => {
      DisplayHighlight(false);

      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        DisplayHighlight(true);
      }, 100);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [highlight]);

  const items = ['Intro', 'About', 'Projects', 'Contact'];

  return (
    <nav>
      <ul>
        {items.map((item, index) => (
          <li
            key={item}
            id={`item-${index}`}
            onClick={() => handleItemClick(item, index)}
            className={highlight === item ? 'active' : ''}
          >
            {item}
          </li>
        ))}
      </ul>
      <div className="highlight" style={highlightStyle} ref={highlightRef}></div>
    </nav>
  );
}

export default Navigation;